<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.history") }} - {{ item.customer_goods_barcode }} -
      {{ item.sku }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table class="table-padding-2 table-h-36">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.time") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.identity_updated") }}
              </th>
              <th
                v-if="customer.id_company_type == 2"
                role="columnheader"
                class="text-center"
              >
                {{ $t("labels.vat") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.price") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.wholesale_price") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                v-if="checkModule(['goods_update_insurance'])"
              >
                {{ $t("labels.insurance_value") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.from_date") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in items"
              :key="`p_${key}`"
              class="text-center"
            >
              <td>
                {{ formatDateTime(item.created_at) }}
              </td>
              <td>
                {{ item.identity_name }}
              </td>
              <td v-if="customer.id_company_type == 2">
                {{ item.vat_txt }}
              </td>
              <td>
                {{ item.price != null ? formatNumber(item.price) : "" }}
              </td>
              <td>
                {{
                  item.wholesale_price != null
                    ? formatNumber(item.wholesale_price)
                    : ""
                }}
              </td>
              <td v-if="checkModule(['goods_update_insurance'])">
                {{
                  item.insurance_value != null
                    ? formatNumber(item.insurance_value)
                    : ""
                }}
              </td>
              <td>
                {{ formatDateTime(item.from_date, "DD/MM/YYYY") }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { VN_VAT_OPTIONS } from "@/libs/const";

export default {
  name: "UpdatePrice",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    customer: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    vatOptions: [...VN_VAT_OPTIONS],
    items: [],
  }),
  computed: {},
  mounted() {
    this.getHistory();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    getHistory() {
      httpClient
        .post("/goods-price-history", {
          id: this.item.id,
        })
        .then(({ data }) => {
          this.items = [...data].map((row) => {
            const vat = [...this.vatOptions].find((v) => v.value == row.vat);
            return {
              ...row,
              vat_txt: (vat && vat.text) || null,
            };
          });
        })
        .catch();
    },
  },
};
</script>

<style scoped></style>
